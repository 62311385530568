@import url(https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #008080;
}

@media (max-width: 889px) {
  h1 {
    margin-right: 50px;
  }
  .window,
  button {
    max-width: 90%;
  }
  .title {
    font-size: 300%;
  }
  .window {
    margin-left: 4.5%;
    margin-top: 10px;
  }
  .toptitle {
    display: none;
  }
  .undergif {
    margin-left: 20px;
    margin-right: 20px;
  }
  .wolf {
    width: 300px;
    margin-bottom: 30px;
  }
}

@media (max-width: 590px) {
  .barb {
    max-width: 75%;
  }
}

@media (min-width: 889px) {
  .title {
    display: none;
  }
  .window {
    margin-left: 16px;
    margin-top: 6px;
  }
  .box {
    max-width: 500px;
    min-width: 500px;
  }
  .boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .undergif {
    margin-left: 300px;
    margin-right: 300px;
  }
}

.title {
  padding-left: 40px;
  text-decoration: underline;
}

a {
  text-decoration: none;
}

h1,
h2 {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  color: #800040;
  font-family: "Syne Mono", monospace;
  font-size: larger;
}

p.desc {
  color: #800040;
  font-family: "Syne Mono", monospace;
  font-size: larger;
}

.box,
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  height: 400px;
  /* width: 400px; */
  padding: 10px;
  margin: 20px;
}

.middle {
  padding-left: 20px;
  padding-right: 20px;
}

::-webkit-scrollbar {
  width: 0;
}
#scrollPath {
  position: fixed;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
}
#progressbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0%,
  100% {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  50% {
    -webkit-filter: hue-rotate(360deg);
            filter: hue-rotate(360deg);
  }
}
#progressbar:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
#progressbar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  -webkit-filter: blur(30px);
          filter: blur(30px);
}

#scrollPathTwo {
  position: fixed;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
}
#progressbarTwo {
  position: fixed;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  animation: animate 5s linear infinite;
}

#progressbarTwo:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
#progressbarTwo:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  -webkit-filter: blur(30px);
          filter: blur(30px);
}

.wolf {
  margin-top: 50px;
  max-width: 90%;
  max-height: 90%;
  height: auto;
}

footer {
  margin-top: 50px;
}

